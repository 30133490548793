export const Shure = () => (
  <>
    <img src="/assets/images/other/ZB357-15_960_720.jpg" alt="" />

    <p>
      shure
      <br />
      neubau der europazentrale in eppingen
    </p>
    <p>
      LP 1-8
      <br />
      für Markus Mucha
    </p>

    <img src="/assets/images/other/ZB357-01_960_720.jpg" alt="" />

    <img src="/assets/images/other/ZB357-06_960_720.jpg" alt="" />

    <img src="/assets/images/other/ZB357-16_960_720.jpg" alt="" />

    <img src="/assets/images/other/ZB357-20_960_720.jpg" alt="" />
  </>
);
