export const Arno = () => (
  <>
    <img src="/assets/images/other/staff_at_work_01_960_640.jpg" alt="" />

    <p>
      arno
      <br />
      erweiterung eingangsbereich
    </p>
    <p>mit c+</p>

    <img
      src="/assets/images/other/Geb_ude_Wolfschlugen020_960_640.JPG"
      alt=""
    />

    <img src="/assets/images/other/staff_at_work_06.jpg" alt="" />

    <img src="/assets/images/other/08a.jpg" alt="" />
  </>
);
