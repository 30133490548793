export const Privacy = () => (
  <>
    <p>
      <span style={{ fontSize: "14px" }}>
        Datenschutzerklärung von Architekturbüro sint/flut
      </span>
    </p>
    <p>
      <strong>1. Gegenstand dieser Datenschutzerklärung</strong>
      <br />
      Der Schutz Ihrer personenbezogenen Daten (im Folgenden kurz ,,Daten“) ist
      uns ein großes und sehr wichtiges Anliegen. Nachfolgend möchten wir Sie
      daher ausführlich darüber informieren, welche Daten bei uns erhoben und
      wie diese von uns im Folgenden verarbeitet oder genutzt werden, ebenso
      wie, welche begleitenden Schutzmaßnahmen wir auch in technischer und
      organisatorischer Hinsicht getroffen haben.
    </p>
    <p>
      <strong>2. Verantwortliche Stelle/Diensteanbieter</strong>
      <br />
      Verantwortlicher nach Art. 4 DSGVO und zugleich Diensteanbieter im Sinne
      des Telemediengesetzes (TMG) ist
      <br />
      Oliver Sint
      <br />
      Kellergasse 37, 74223 Flein
      <br />
      Telefon: 07131 576814
      <br />
      E-Mail: post (a) sint-flut.de
    </p>
    <p>
      Oliver Sint ist verantwortlich nach § 55 des Rundfunk - Staatsvertrags.
    </p>
    <p>
      <strong>3. Erhebung und Verwendung Ihrer Daten</strong>
      <br />
      Alle personenbezogenen Daten, die wir von Ihnen erheben, werden wir nur zu
      dem angegebenen Zweck erheben, verarbeiten und nutzen. Dabei beachten wir,
      dass dies nur im Rahmen der jeweils geltenden Rechtsvorschriften bzw.
      ansonsten nur mit Ihrer Einwilligung geschieht.Sie können gemäß Art. 21
      DSGVO in den dort genannten Fällen Widerspruch gegen die Verarbeitung
      <br />
      Ihrer Daten erheben. Bitte wenden Sie sich an "post (a) sint-flut.de" oder
      senden Sie uns Ihr Verlangen per Post.
    </p>
    <p>
      Sie haben das Recht zur Beschwerde bei der zuständigen Aufsichtsbehörde
      für den Datenschutz
    </p>
    <p>
      Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit
      <br />
      Königstrasse 10 a, 70173 Stuttgart
      <br />
      Telefon: 0711/615541-0
      <br />
      Telefax: 0711/615541-15
      <br />
      E-Mail: poststelle@lfdi.bwl.de
      <br />
      www.baden-wuerttemberg.datenschutz.de/
    </p>
    <p>
      Umfang und Art der Erhebung und Verwendung Ihrer Daten unterscheidet sich
      danach, ob Sie unseren Internetauftritt nur zum Abruf von Informationen
      besuchen oder von uns angebotene Leistungen in
      <br />
      Anspruch nehmen:
    </p>
    <p>
      <strong>a) Internetnutzung</strong>
      <br />
      Für die nur informatorische Nutzung unseres Internetauftritts ist es
      grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten angeben.
    </p>
    <p>
      Vielmehr erheben und verwenden wir in diesem Fall nur diejenigen Ihrer
      Daten, die uns Ihr Internetbrowser automatisch übermittelt, wie etwa:
      <br />
      •Datum und Uhrzeit des Abrufs einer unserer Internetseiten
      <br />
      •Ihren Browsertyp
      <br />
      •die Browser-Einstellungen
      <br />
      •das verwendete Betriebssystem
      <br />
      •die von Ihnen zuletzt besuchte Seite
      <br />
      •die übertragene Datenmenge und der Zugriffsstatus (Datei übertragen,
      Datei nicht gefunden etc.) sowie
      <br />
      •Ihre IP-Adresse.
    </p>
    <p>
      Diese Daten erheben und verwenden wir bei einem informatorischen Besuch
      ausschließlich in nichtpersonenbezogener Form. Dies erfolgt, um die
      Nutzung der von Ihnen abgerufenen Internetseiten überhaupt zu ermöglichen,
      zu statistischen Zwecken sowie zur Verbesserung unseres Internetangebots.
      Die IP-Adresse speichern wir nur für die Dauer Ihres Besuchs, eine
      personenbezogene Auswertung findet nicht statt. Eine Zusammenführung
      dieser Daten mit anderen Datenquellen wird nicht vorgenommen, die Daten
      werden zudem nach einer statistischen Auswertung gelöscht. Dies ist nach
      der Entscheidung des Europäischen Gerichtshofs vom 19.10.2016 (Az.: C
      582/14) auch in Anbetracht des Telemediengesetzes zulässig.
    </p>
    <p>
      <strong>
        b) Nutzung von Angeboten bzw. Datenverwendung zur Aufgabenerfüllung
      </strong>
      <br />
      Soweit Sie von uns angebotene Leistungen in Anspruch nehmen wollen, ist es
      möglicherweise nötig, dass Sie dazu weitere Daten angeben. Es handelt sich
      um diejenigen Daten, die zur jeweiligen Abwicklung erforderlich sind.
      Weitere Angaben können Sie ggf. freiwillig tätigen; sie sind von uns
      entsprechend als optional markiert.
    </p>
    <p>
      Ein Teil der Leistungen wird auch im Loginbereich der Homepage
      bereitgestellt.
    </p>
    <p>
      Die Erhebung oder Verwendung Ihrer Daten erfolgt zu dem Zweck, die von
      Ihnen gewünschte notwendige Leistung zu erbringen.
    </p>
    <p>
      Ihre Daten werden zu vorgenanntem Zweck ggf. an uns unterstützende
      Dienstleister weitergegeben, die wir selbstverständlich sorgfältig
      ausgewählt und zur Einhaltung des Datenschutzrechts verpflichtet haben.
      Dabei kann es sich insbesondere um technische Dienstleister oder
      Versanddienstleister (Lettershop etc.) handeln.
    </p>
    <p>
      Die Weitergabe Ihrer Daten an andere Dritte erfolgt ansonsten nur, wenn
      dies gesetzlich erlaubt ist oder wir Ihre explizite Einwilligung dafür
      erhalten haben.
    </p>
    <p>
      <strong>4. Datenschutzrechtliche Einwilligung</strong>
      <br />
      Über die Abwicklung der von Ihnen gewünschten Leistungen hinaus möchten
      wir gerne – selbstverständlich nur, soweit Sie darin an gesonderter Stelle
      explizit einwilligen – einen auf Ihre Interessen ausgerichteten
      Internetauftritt bieten sowie Ihnen gelegentlich per Post oder E-Mail (in
      Form unseres Newsletters) für Sie auf Basis Ihrer Daten interessante
      Neuigkeiten und Hinweise über uns zukommen lassen und Ihre Daten zur dazu
      notwendigen Auswertung sowie zu Marktforschungszwecken verwenden. Hierfür
      ist es technisch nötig, dass wir Ihre anfallenden sowie angegebenen Daten
      in Nutzungsprofilen zusammenfassen und diese zu vorgenannten Zwecken
      auswerten. Dies erfolgt lediglich intern und nur zu vorgenannten Zwecken.
    </p>
    <p>
      Für die Veröffentlichung von Bildmaterial, das Ihrem Urheberrecht
      unterliegt oder auf dem Sie abgebildet sind, benötigen wir unter Umständen
      ebenfalls Ihre Einwilligung.
    </p>
    <p>
      Ihre Einwilligung können Sie gesondert abgeben. Sie können sie im
      Anschluss jederzeit mit Wirkung für die Zukunft widerrufen.
    </p>
    <p>
      Die Einwilligung in Bezug auf Cookies bzw. Werbetracker richtet sich nach
      untenstehendem § 6 dieser Erklärung.
    </p>
    <p>
      <strong>5. Newsletter</strong>
      <br />
      Um sich bei unserem E-Mail-Newsletterdienst anmelden zu können, benötigen
      wir neben Ihrer datenschutzrechtlichen Einwilligung mindestens Ihre
      E–Mail-Adresse, an die der Newsletter versendet werden soll. Etwaige
      weitere Angaben sind freiwillig und werden verwendet, um Sie persönlich
      ansprechen und den Inhalt des Newsletters persönlich ausgestaltet zu
      können sowie Rückfragen zur E-Mail-Adresse klären zu können.
    </p>
    <p>
      Für den Newsletterversand verwenden wir in der Regel das sog. Double
      Opt-In-Verfahren, d.h. wir werden Ihnen erst dann den Newsletter zusenden,
      wenn Sie zuvor Ihre Anmeldung über eine Ihnen zu diesem Zweck zugesandte
      Bestätigungs-E-Mail per darin enthaltenem Link bestätigen. Damit möchten
      wir sicherstellen, dass nur Sie selbst sich als Inhaber der angegebenen
      E-Mail-Adresse zum Newsletter anmelden können. Ihre diesbezügliche
      Bestätigung muss zeitnah nach Erhalt der Bestätigungs-E-Mail erfolgen, da
      andernfalls Ihre Newsletter-Anmeldung automatisch aus unserer Datenbank
      gelöscht wird.
    </p>
    <p>
      Sie können einen von Ihnen bei uns abonnierten Newsletter jederzeit
      abbestellen. Dazu können Sie uns entweder eine formlose E-Mail zusenden
      oder über den Link am Ende des Newsletters eine Stornierung vornehmen.
    </p>
    <p>
      <strong>6. Einsatz von Cookies</strong>
      <br />
      Für unseren Internetauftritt nutzen wir die Technik der Cookies. Cookies
      sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer
      Internetseiten von unserem Webserver an Ihren Browser gesandt und von
      diesem auf Ihrem Rechner für einen späteren Abruf vorgehalten werden. Wir
      verwenden ebenfalls sogenannte Web Beacons (unsichtbare Grafiken). Durch
      diese Web Beacons können Informationen wie der Besuch auf unseren
      Webseiten ausgewertet werden. Die durch Cookies und Web Beacons erzeugten
      Informationen über die Benutzung dieser Website (einschließlich der
      IP-Adresse der Nutzer) und Auslieferung von Werbung können auch an Server
      von Google LLC in der Europäischen Union und den USA übertragen und dort
      gespeichert werden. (vgl. Nr.8). Die weitergebenen Daten können nicht mit
      anderen von Ihnen gespeicherten Daten zusammengeführt werden.
    </p>
    <p>
      Ob Cookies gesetzt und abgerufen werden können, können Sie durch die
      Einstellungen in Ihrem Browser selbst bestimmen. Sie können in Ihrem
      Browser etwa das Speichern von Cookies gänzlich deaktivieren, es auf
      bestimmte Webseiten beschränken oder Ihren Browser so konfigurieren, dass
      er Sie automatisch benachrichtigt, sobald ein Cookie gesetzt werden soll
      und Sie um Rückmeldung dazu bittet. Für den vollen Funktionsumfang unseres
      Internetauftritts ist es allerdings aus technischen Gründen erforderlich,
      die genannten Session Cookies zuzulassen.
    </p>
    <p>
      Ab dem Inkrafttreten der Datenschutzgrundverordnung am 25.05.2018 werden
      wir vor der Nutzung von Cookies Ihre explizite Zustimmung für die Nutzung
      von Cookies vor deren Verwendung einholen.
    </p>
    <p>
      <strong>7. Widerrufs- und Widerspruchsrecht</strong>
      <br />
      Wir weisen Sie darauf hin, dass Sie eine uns ggf. erteilte
      datenschutzrechtliche Einwilligung jederzeit mit Wirkung für die Zukunft
      widerrufen können. Soweit gesetzliche Anforderungen zur Erhebung von Daten
      bestehen (z.B. Architektenliste), besteht kein Widerrufsrecht.
    </p>
    <p>
      <strong>8. Einsatz von Statistiktools</strong>
    </p>
    <p>
      <strong>Google Analytics</strong>
      <br />
      Uns ist es wichtig, unsere Internetseiten möglichst optimal auszugestalten
      und damit für unsere Besucher attraktiv zu machen. Dazu ist es nötig, dass
      wir wissen, welche Teile davon wie bei unseren Besuchern ankommen.
    </p>
    <p>
      Unsere Webseite benutzt Google Analytics, einen Webanalysedienst der
      Google LLC (,,Google“). Google Analytics verwendet ebenfalls Cookies, also
      Textdateien, die auf Ihrem Computer gespeichert werden und die eine
      Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch den
      Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden
      in der Regel an einen Server von Google in den USA übertragen und dort
      gespeichert. Im Fall der Aktivierung der IP-Anonymisierung auf dieser
      Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von
      Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
      Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
      Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den
      USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese
      Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um
      Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit
      der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen
      gegenüber uns als Webseitenbetreiber zu erbringen.
    </p>
    <p>
      Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
      IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
    </p>
    <p>
      Sie können die Speicherung der Cookies durch eine entsprechende
      Einstellung Ihrer Browsersoftware verhindern; wir weisen Sie jedoch darauf
      hin, dass Sie in diesem Fall ggf. nicht sämtliche Funktionen dieser
      Webseite vollumfänglich werden nutzen können.
    </p>
    <p>
      Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und
      auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an
      Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem
      sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen
      und installieren:
    </p>
    <p>http://tools.google.com/dlpage/gaoptout?hl=de</p>
    <p>
      <strong>9. Datensicherheit</strong>
      <br />
      Wir setzen zudem technische und organisatorische Sicherheitsmaßnahmen ein,
      um anfallende oder erhobene personenbezogene Daten zu schützen, insb.
      gegen zufällige oder vorsätzliche Manipulation, Verlust, Zerstörung oder
      gegen den Angriff unberechtigter Personen. Unsere Sicherheitsmaßnahmen
      werden entsprechend der technologischen Entwicklung fortlaufend
      verbessert.
    </p>
    <p>
      Wir stellen Ihnen diverse Online-Formulare und Dienste bereit, mit denen
      Sie personenbezogene Daten an uns senden können. Diese Formulare sind
      gegen Einsichtnahme Dritter durch die Verwendung von TLS-Verschlüsselung
      geschützt. Die Daten, die Sie eingeben oder als Datei an uns übermitteln,
      können von uns gespeichert und nach Vereinbarung verarbeitet werden.
      Sofern die Nutzung und Verarbeitung der Einwilligung des Nutzers oder von
      Dritten bedarf, kann die Einwilligung jederzeit ohne Angaben von Gründen
      widerrufen werden. In diesem kann jedoch evtl. die Vertragserfüllung durch
      uns beeinträchtigt werden.
    </p>
    <p>
      Je nach Service können Sie zu verschiedenen Eingaben zur Identifizierung
      bzw. Verhinderung von Missbrauch aufgefordert werden:
    </p>
    <p>
      a) Zur Identifizierung bei der Anlieferung von Daten kann die Eingabe
      einer benutzerdefinierten Kennung oder anderweitigen geeigneten
      Authentifikation verlangt werden. Die Daten werden über SFTP oder HTTPS
      gegen Kenntnisnahme Dritter geschützt, sofern der Nutzer die von uns
      empfohlenen Wege der Datenübermittlung nutzt.
    </p>
    <p>
      b) Zur Verhinderung der Nutzung durch Maschinen können sogenannte CAPTCHAS
      verwendet werden, die Bilder oder Aufgaben enthalten, die von
      Computerskripten nicht verarbeitet werden können.
    </p>
    <p>
      <strong>10. Löschfristen</strong>
      <br />
      Wir speichern personenbezogene Daten jeweils nur so lange, bis der Zweck
      der Datenspeicherung entfällt, so lange keine gesetzlichen
      Aufbewahrungsfristen oder Verjährungsfristen von möglicherweise der
      Rechtsverfolgung dienlichen Daten der Löschung entgegenstehen (in diesem
      Fall wird die Verarbeitung der Daten nach Art. 18 DSGVO eingeschränkt).
    </p>
    <p>
      <strong>11. Betroffenenrechte</strong>
      <br />
      Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich
      ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen, so
      richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger
      Identifizierung Ihrer Person an die oben genannte Adresse des
      Verantwortlichen.
      <br />
      Nachfolgend finden Sie eine Übersicht über Ihre Rechte.
    </p>
    <p>
      <strong>a) Recht auf Bestätigung und Auskunft</strong>
      <br />
      Sie haben jederzeit das Recht, von uns eine Bestätigung darüber zu
      erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden.
      Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche
      Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten nebst
      einer Kopie dieser Daten zu erlangen.
    </p>
    <p>Des Weiteren besteht ein Recht auf folgende Informationen:</p>
    <p>
      1. die Verarbeitungszwecke;
      <br />
      2. die Kategorien personenbezogener Daten, die verarbeitet werden;
      <br />
      3. die Empfänger oder Kategorien von Empfängern, gegenüber denen die
      personenbezogenen Daten offengelegt worden sind oder noch offengelegt
      werden, insbesondere bei Empfängern in Drittländern oder bei
      internationalen Organisationen;
      <br />
      4. falls möglich die geplante Dauer, für die die personenbezogenen Daten
      gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für
      die Festlegung dieser Dauer;
      <br />
      5. das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
      betreffenden personenbezogenen Daten oder auf Einschränkung der
      Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
      gegen diese Verarbeitung;
      <br />
      6. das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
      <br />
      7. wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle
      verfügbaren Informationen über die Herkunft der Daten;
      <br />
      8. das Bestehen einer automatisierten Entscheidungsfindung einschließlich
      Profiling gemäß Art. 22 Abs.1 und 4 DSGVO und – zumindest in diesen Fällen
      – aussagekräftige Informationen über die involvierte Logik sowie die
      Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung
      für Sie.
    </p>
    <p>
      Werden personenbezogene Daten an ein Drittland oder an eine internationale
      Organisation übermittelt, so haben Sie das Recht, über die geeigneten
      Garantien gemäß Art. 46 DSGVO im Zusammenhang mit der Übermittlung
      unterrichtet zu werden.
    </p>
    <p>
      <strong>b) Recht auf Berichtigung</strong>
      <br />
      Sie haben das Recht, von uns unverzüglich die Berichtigung Sie
      betreffender unrichtiger personenbezogener Daten zu verlangen. Unter
      Berücksichtigung der Zwecke haben Sie das Recht, die Vervollständigung
      unvollständiger personenbezogener Daten – auch mittels einer ergänzenden
      Erklärung – zu verlangen.
    </p>
    <p>
      <strong>c) Recht auf Löschung („Recht auf Vergessenwerden“)</strong>
      <br />
      Sie haben das Recht, von uns zu verlangen, dass Sie betreffende
      personenbezogene Daten unverzüglich gelöscht werden, und wir sind
      verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer
      der folgenden Gründe zutrifft:
    </p>
    <p>
      1. Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben
      oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
      <br />
      2. Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß
      Art. 6 Abs.1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es
      fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
      <br />
      3. Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung
      ein und es liegen keine vorrangigen berechtigten Gründe für die
      Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch
      gegen die Verarbeitung ein.
      <br />
      4. Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
      <br />
      5. Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
      rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
      Mitgliedstaaten erforderlich, dem wir unterliegen.
      <br />
      6. Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
      Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
    </p>
    <p>
      Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich
      ist
    </p>
    <p>
      1. zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
      <br />
      2. zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung
      nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche
      unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
      öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
      die dem Verantwortlichen übertragen wurde;
      <br />
      3. aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
      Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
      <br />
      4. für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche
      oder historische Forschungszwecke oder für statistische Zwecke gem. Art.
      89 Abs.1 DSGVO, soweit das unter Abschnitt a) genannte Recht
      voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich
      macht oder ernsthaft beeinträchtigt, oder
      <br />
      5. zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
    </p>
    <p>
      Haben wir die personenbezogenen Daten öffentlich gemacht und sind wir
      gemäß Art. 17 DSGVO zu deren Löschung verpflichtet, so treffen wir unter
      Berücksichtigung der verfügbaren Technologie und der
      Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für
      die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten
      verarbeiten, darüber zu informieren, dass Sie von ihnen die Löschung aller
      Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen
      dieser personenbezogenen Daten verlangt haben.
    </p>
    <p>
      <strong>d) Recht auf Einschränkung der Verarbeitung</strong>
      <br />
      Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu
      verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
    </p>
    <p>
      1. die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten,
      und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der
      personenbezogenen Daten zu überprüfen,
      <br />
      2. die Verarbeitung unrechtmäßig ist und&nbsp; Sie die Löschung der
      personenbezogenen Daten ablehnten und stattdessen die Einschränkung der
      Nutzung der personenbezogenen Daten verlangt;
      <br />
      3. wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht
      länger benötigen, Sie die Daten jedoch zur Geltendmachung, Ausübung oder
      Verteidigung von Rechtsansprüchen benötigten, oder
      <br />
      4. Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO
      eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe
      unseres Unternehmens gegenüber den Ihren überwiegen.
    </p>
    <p>
      Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
      eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur
      mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
      von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
      oder juristischen Person oder aus Gründen eines wichtigen öffentlichen
      Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
    </p>
    <p>
      <strong>e) Recht auf Datenübertragbarkeit</strong>
      <br />
      Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die uns
      bereitgestellt haben, in einem strukturierten, gängigen und
      maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten
      einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln,
      sofern
    </p>
    <p>
      1. die Verarbeitung auf einer Einwilligung gemäß Art. 6 Abs. 1 lit. a
      DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß Art.6
      Abs. 1 lit. b DSGVO beruht und
      <br />
      2. die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
      <br />
      3.
      <br />
      Bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Absatz 1
      haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt
      von uns einem anderen Verantwortlichen übermittelt werden, soweit dies
      technisch machbar ist.
    </p>
    <p>
      Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
      personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
      erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
      öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
    </p>
    <p>
      <strong>f) Widerspruchsrecht</strong>
      <br />
      Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
      ergeben, jederzeit gegen die Verarbeitung Sie betreffender
      personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
      DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
      Bestimmungen gestütztes Profiling. Wir verarbeiten die personenbezogenen
      Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe
      für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
      Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
      Ausübung oder Verteidigung von Rechtsansprüchen.
    </p>
    <p>
      Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu
      betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
      Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
      derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es
      mit solcher Direktwerbung in Verbindung steht.
    </p>
    <p>
      Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
      ergeben, gegen die Sie betreffende Verarbeitung sie betreffender
      personenbezogener Daten, die zu wissenschaftlichen oder historischen
      Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO
      erfolgt, Widerspruch einzulegen, es sei denn, die Verarbeitung ist zur
      Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.
    </p>
    <p>
      <strong>g) Automatisierte Entscheidungen einschließlich Profiling</strong>
      <br />
      Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten
      Verarbeitung – einschließlich Profiling – beruhenden Entscheidung
      unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet
      oder Sie in ähnlicher Weise erheblich beeinträchtigt.
    </p>
    <p>
      <strong>
        h) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
      </strong>
      <br />
      Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener
      Daten jederzeit zu widerrufen.
    </p>
    <p>
      <strong>i) Recht auf Beschwerde bei einer Aufsichtsbehörde</strong>
      <br />
      Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde,
      insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres
      Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, Sie der Ansicht
      sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten
      rechtswidrig ist.
    </p>
    <p>
      <strong>j) Recht auf Unterrichtung</strong>
      <br />
      Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
      Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser
      verpflichtet, allen Empfängern, denen die Sie betreffenden
      personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
      Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei
      denn, dies erweist sich als unmöglich oder ist mit einem
      unverhältnismäßigen Aufwand verbunden.
    </p>
    <p>
      Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
      Empfänger unterrichtet zu werden.
    </p>
  </>
);
