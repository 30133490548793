export const ShureAusbau = () => (
  <>
    <img src="/assets/images/other/ZB357-35_960_720.jpg" alt="" />

    <p>
      shure - ausbau
      <br />
      innenraumgestaltung der europazentrale
    </p>
    <p>
      LP 1-8
      <br />
      für Markus Mucha
    </p>
    <p>
      Photographien:
      <br />
      <a href="http://www.zooeybraun.de/">zooey braun</a>, stuttgart
    </p>

    <img src="/assets/images/other/ZB357-30.jpg" alt="" />

    <img src="/assets/images/other/ZB357-03_960_720.jpg" alt="" />

    <img src="/assets/images/other/ZB357-34_960_720.jpg" alt="" />

    <img src="/assets/images/other/ZB357-41_960_720.jpg" alt="" />

    <img src="/assets/images/other/ZB357-27.jpg" alt="" />
  </>
);
