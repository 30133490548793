export const GartenschauEppingen = () => (
  <>
    <img src="/assets/images/other/2022-05-18_10.37.14_960_540.jpg" alt="" />

    <img src="/assets/images/other/2022-05-18_10.39.36_960_1707.jpg" alt="" />

    <img src="/assets/images/other/2022-05-18_10.43.00_960_540.jpg" alt="" />

    <img src="/assets/images/other/2022-05-18_10.54.25_960_1707.jpg" alt="" />

    <img src="/assets/images/other/2022-05-18_10.36.04_960_1707.jpg" alt="" />

    <img src="/assets/images/other/2022-05-18_10.53.26_960_540.jpg" alt="" />

    <img
      src="/assets/images/other/Gartenschau_Eppingen_Modelbild_1.jpg"
      alt=""
    />

    <img
      src="/assets/images/other/Gartenschau_Eppingen_Modelbild_2.jpg"
      alt=""
    />

    <img
      src="/assets/images/other/Gartenschau_Eppingen_Modelbild_3.jpg"
      alt=""
    />

    <img
      src="/assets/images/other/Gartenschau_Eppingen_Modelbild_4.jpg"
      alt=""
    />
  </>
);
