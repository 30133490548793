import { styled } from "../stitches.config";

export const Main = styled("div", {
  marginTop: "100px",
  width: "80%",
  position: "relative",
  "& img": {
    width: "100%",
    height: "auto",
    marginBottom: "20px",
  },
  "& p": {
    marginBottom: "10px",
    textAlign: "justify",
    "& a": {
      transition: "background-color 0.5s",
      color: "#6b8e99",
      textDecoration: "none",
      "&:hover": {
        color: "#526166",
        backgroundColor: "#b8c7cc",
      },
    },
  },
  "@bp1": {
    order: 2,
    width: "100%",
    marginTop: "20px",
    paddingInline:"10px"
  },
});
