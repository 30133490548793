import { createStitches } from "@stitches/react";

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      gray400: "gainsboro",
      gray500: "lightgray",
    },
    fonts: {
      mono: "letter_gothic_linemonospace, 'Lucida Console', Monaco, monospace",
    },
  },
  media: {
    bp1: "(max-width: 800px)",
  },
});

export const globalStyles = globalCss({
  "*": { margin: 0, padding: 0, boxSizing: "border-box" },
  "@font-face": {
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "letter_gothic_linemonospace",
    src: `url('/assets/fonts/letgothl-webfont.woff') format('woff'), 
                url('/assets/fonts/letgothl-webfont.eot?#iefix') format('embedded-opentype'),
                url('/assets/fonts/letgothl-webfont.eot'),
                url('/assets/fonts/letgothl-webfont.ttf') format('truetype'),
                url('/assets/fonts/letgothl-webfont.svg#letter_gothic_linemonospace') format('svg')`,
  },
  "html, body": {
    position: "relative",

    /* Global Font Sizes */
    fontFamily: "'Lucida Console', Monaco, monospace",
    fontSize: "12px",
    /*     lineHeight: "16px", */
    width: "100%",
    height: "100%",
    color: "rgba(0,0,0,0.60)",
    /* background-color: #e6eff2; */
    backgroundColor: "#FFF",
    scrollBehavior:"smooth"
  },
  body: {
    overflowY: "scroll",
  },
});

export const postClass = css({
  marginBottom: "40px",
});
