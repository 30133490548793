export const Contact = () => (
  <>
    <img src="/assets/images/other/ZB357-03_960_720.jpg" alt="" />

    <p>sint/flut</p>
    <p>
      <a href="mailto:post@sint-flut.de">post@sint-flut.de</a>
    </p>
    <p>hauffstraße 3, 74223 flein</p>
    <p>
      fon:&nbsp;&nbsp; +49 (0)7131 576 814
      <br />
      fax:&nbsp;&nbsp; +49 (0)711 1237506
      <br />
      funk:&nbsp; +49 (0)172 7123905
    </p>
  </>
);
