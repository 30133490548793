import { styled } from "../stitches.config";

const StyledHeader = styled("header", {
  color: "rgba(0,0,0,0.80)",
  transition: "color 0.5s",
  fontSize: "24px",
  paddingTop: "70px",
  "& a": {
    color: "inherit",
    textDecoration: "none",

    "&:hover": {
      color: "#3d5c66",
    },
  },
  "& h1": {
    fontSize: "24px",
    margin: "0 0 10px 20px",
  },
  "@bp1": {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#17191a",
    color: "rgba(255,255,255,0.8)",
    padding: "10px 20px 10px 0",
    width: "100%",
    textAlign: "right",
    "& a": {
      fontSize: "12px",
    },
    h1: {
      fontSize: "20px",
      margin: "0",
      color: "rgba(255,255,255,1)",
    },
  },
});

export const Title = () => {
  return (
    <StyledHeader id="top">
      <a href="/">
        <h1>sint/flut</h1>
      </a>
    </StyledHeader>
  );
};
