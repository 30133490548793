export const L28 = () => (
  <>
    <img src="/assets/images/other/IMG_4491_gerade.jpg" alt="" />

    <p>
      L28
      <br />
      neubau eines einfamilienwohnhauses in ludwigsburg
    </p>
    <p>
      baubeginn 2011
      <br />
      fertigstellung 2012
    </p>

    <img src="/assets/images/other/2013-05-06_14.37.49.jpg" alt="" />

    <img src="/assets/images/other/2012-10-18_16.59.06.jpg" alt="" />

    <img src="/assets/images/other/Lehberg_Ansicht_960_556.jpg" alt="" />

    <img src="/assets/images/other/Lehberg_EG.jpg" alt="" />
  </>
);
