export const Eberstein = () => (
  <>
    <img src="/assets/images/other/EBERSTEIN01.jpg" alt="" />

    <p>fünffamilienwohnhaus in bretten</p>
    <p>fertigstellung 2021</p>
    <p>LP 1-8</p>

    <img src="/assets/images/other/EBERSTEIN02.jpg" alt="" />

    <img src="/assets/images/other/EBERSTEIN03.jpg" alt="" />

    <img src="/assets/images/other/EBERSTEIN04.jpg" alt="" />

    <img src="/assets/images/other/EBERSTEIN05.jpg" alt="" />
  </>
);
