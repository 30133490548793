export const Kirchensall = () => (
  <>
    <img src="/assets/images/other/KIRCHENSALL_Final02.jpg" alt="" />

    <p>
      <strong>kirchensall</strong>
      <br />
      neubau einer aussegnungshalle nach erfolgreichem wettbewerb
    </p>
    <p>
      LP 1-8
      <br />
      Fertigstellung&nbsp; Februar 2015
    </p>

    <img src="/assets/images/other/KIRCHENSALL_04_a.jpg" alt="" />

    <img src="/assets/images/other/KIRCHENSALL_12_a_Kopie.jpg" alt="" />

    <img src="/assets/images/other/KIRCHENSALL_04.jpg" alt="" />

    <img src="/assets/images/other/IMG_6109.JPG" alt="" />

    <img src="/assets/images/other/Kirchensall_1.jpg" alt="" />
  </>
);
