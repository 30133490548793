export const BugaPavillon = () => (
  <>
    <img src="/assets/images/other/BUGA01.jpg" alt="" />

    <p>
      pavillon des landkreises heilbronn auf der bundesgartenschau 2019&nbsp;
    </p>
    <p>wettbewerb und entwurf Herzog&amp;Herzog</p>
    <p>bilder: dietmar strauß bildermacher</p>

    <img src="/assets/images/other/BUGA02.jpg" alt="" />

    <img src="/assets/images/other/BUGA03.jpg" alt="" />

    <img src="/assets/images/other/BUGA04.jpg" alt="" />

    <img src="/assets/images/other/BUGA05.jpg" alt="" />

    <img src="/assets/images/other/BUGA06.jpg" alt="" />

    <img src="/assets/images/other/BUGA07.jpg" alt="" />
  </>
);
