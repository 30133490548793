export const XcelCampus = () => (
  <>
    <img src="/assets/images/other/IMG_1327_960_640.JPG" alt="" />
    <p>
      xcel campus
      <br />
      freiflächengestaltung des xcel campus in zuffenhausen
    </p>
    <p>
      LP 8<br />
      für AG37
      <br />
      &nbsp;
    </p>
    <img src="/assets/images/other/IMG_1338_960_1440.JPG" alt="" />
    <img src="/assets/images/other/IMG_1350_960_1440.JPG" alt="" />
  </>
);
