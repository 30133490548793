import { styled } from "../stitches.config";

const StyledGoUp = styled("div", {
  textAlign: "right",
  marginTop: "20px",
  "& a": {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.6)",
    "&:hover": {
      color: "rgba(0,0,0,1)",
    },
  },
  "@bp1": {
    marginRight: "20px",
  },
});
export const GoUp = () => (
  <StyledGoUp>
    <a href="#top">Nach Oben</a>
  </StyledGoUp>
);
