import { styled } from "../stitches.config";
export const Wrapper = styled("div", {
  width: "75%",
  maxWidth: "1260px",
  minWidth: "760px",
  margin: "0 auto",
  paddingBottom: "50px",
  height: "auto",
  position: "relative",
  "@bp1": {
    minWidth: "auto",
    width: "100%",
  },
});
