export const Impressum = () => (
  <>
    <img src="/assets/images/other/impressum_960_404.jpg" alt="" />

    <p>dipl. ing. oliver sint, freier architekt</p>
    <p>
      hauffstraße 3<br />
      74223 flein
    </p>
    <p>
      fon: 07131 576814
      <br />
      …………………………..
    </p>
    <p>Mitgliedsnummer:&nbsp; 087280</p>
    <p>Umsatzsteueridentifikationsnummer:</p>
    <p>
      Architektenkammer Baden-Württemberg:
      <br />
      www.akbw.de
    </p>
    <p>
      Photographien:
      <br />
      zooey braun, stuttgart / www.zooeybraun.de
      <br />
      oliver sint
    </p>
    <p>
      Haftungshinweis:
      <br />
      Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für
      die Inhalte externer Links.
      <br />
      Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
      verantwortlich.
    </p>
    <p>
      Rechtlicher Hinweis:
      <br />
      Die auf dieser Webseite befindlichen Inhalte sind urheberrechtlich
      geschützt. Unerlaubte Vervielfältigung, Verkauf oder Mißbrauch sind
      untersagt; alle Rechte vorbehalten. Insbesondere dürfen Nachdruck,
      Aufnahme in Online-Dienste und Internet und Vervielfältigung nur nach
      vorheriger schriftlicher Zustimmung erfolgen.
    </p>
  </>
);
