export const UinD = () => (
  <>
    <img src="/assets/images/other/ullmann_1_200_960_717.jpg" alt="" />

    <p>
      u in d<br />
      neubau eines einfamilienwohnhauses mit doppelgarage
    </p>
  </>
);
