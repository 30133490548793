import React from "react";
import { globalStyles } from "./stitches.config";
import { Title } from "./modules/Title";
import { Wrapper } from "./modules/Wrapper";
import { SideBar } from "./modules/SideBar";
import { Main } from "./modules/Main";
import { Slider } from "./modules/Slider";
import { Menu, MobileMenu } from "./modules/Menu";
import { Content } from "./modules/Content";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Blog } from "./pages/Blog";
import { Partners } from "./pages/Partners";
import { CookieBox } from "./modules/CookieBox";
import { Jobs } from "./pages/Jobs";
import { Contact } from "./pages/Contact";
import { Impressum } from "./pages/Impressum";
import { GoUp } from "./modules/GoUp";
import { Privacy } from "./pages/Privacy";
import { Team } from "./pages/Team";
import { Arno } from "./pages/Projects/Arno";
import { BugaPavillon } from "./pages/Projects/BugaPavillon";
import { Eberstein } from "./pages/Projects/Eberstein";
import { GartenschauEppingen } from "./pages/Projects/GartenschauEppingen";
import { H218 } from "./pages/Projects/H218";
import { H36 } from "./pages/Projects/H36";
import { H40 } from "./pages/Projects/H40";
import { K12 } from "./pages/Projects/K12";
import { Kirchensall } from "./pages/Projects/Kirchensall";
import { L28 } from "./pages/Projects/L28";
import { MedienProjektP2 } from "./pages/Projects/MedienProjektP2";
import { Raumblick } from "./pages/Projects/Raumblick";
import { S16 } from "./pages/Projects/S16";
import { Shure } from "./pages/Projects/Shure";
import { ShureAusbau } from "./pages/Projects/ShureAusbau";
import { UinD } from "./pages/Projects/UinD";
import { XcelCampus } from "./pages/Projects/XcelCampus";

function App() {
  globalStyles();
  return (
    <Wrapper>
      <BrowserRouter>
        <CookieBox />
        <Content>
          <Main>
            <Routes>
              <Route path="/" element={<Slider />} />
              <Route path="/aktuell" element={<Blog />} />
              <Route path="/partner" element={<Partners />} />
              <Route path="/stellenausschreibung" element={<Jobs />} />
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/datenschutz" element={<Privacy />} />
              <Route path="/team" element={<Team />} />

              <Route path="/projekte/arno" element={<Arno />} />
              <Route
                path="/projekte/buga-pavillon"
                element={<BugaPavillon />}
              />
              <Route path="/projekte/eberstein" element={<Eberstein />} />
              <Route
                path="/projekte/gartenschau-eppingen"
                element={<GartenschauEppingen />}
              />
              <Route path="/projekte/h218" element={<H218 />} />
              <Route path="/projekte/h36" element={<H36 />} />
              <Route path="/projekte/h40" element={<H40 />} />
              <Route path="/projekte/k12" element={<K12 />} />
              <Route path="/projekte/kirchensall" element={<Kirchensall />} />
              <Route path="/projekte/l28" element={<L28 />} />
              <Route
                path="/projekte/medienprojekt-p2"
                element={<MedienProjektP2 />}
              />
              <Route path="/projekte/raumblick" element={<Raumblick />} />
              <Route path="/projekte/s16" element={<S16 />} />
              <Route path="/projekte/shure" element={<Shure />} />
              <Route path="/projekte/shure-ausbau" element={<ShureAusbau />} />
              <Route path="/projekte/u-in-d" element={<UinD />} />
              <Route path="/projekte/xcel-campus" element={<XcelCampus />} />
            </Routes>
            <GoUp />
          </Main>
          <SideBar>
            <Title />
            <Menu />
            <MobileMenu />
          </SideBar>
        </Content>
      </BrowserRouter>
    </Wrapper>
  );
}

export default App;
