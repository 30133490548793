import * as Accordion from "@radix-ui/react-accordion";
import { styled, keyframes } from "../stitches.config";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

const StyledMenu = styled("div", {
  display: "block",
  marginLeft: "20px",
  "& a": {
    display: "block",
    textDecoration: "none",
    color: "rgba(0,0,0,0.8)",
    "&:hover": {
      color: "rgba(0,0,0,0.4)",
    },
  },

  "@bp1": {
    display: "none",
  },
});

const StyledMobile = styled("div", {
  display: "none",
  "@bp1": {
    display: "flex",
    margin: 0,
    backgroundColor: "rgba(23,25,26,0.90)",
    "& > *": {
      marginLeft: "20px",
      
      borderBottom: "1px solid",
      borderBottomColor: "rgba(255, 255, 255, 0.2)",
      paddingBlock: "5px",
    },
    "& a, & button": {
      display:"block",
      textDecoration: "none",
      color: "rgba(255,255,255,0.50)",
      "&:hover": {
        color: "rgba(0,0,0,0.80)",
      },
    },
  },
});
const Links = () => (
  <>
    <Link to="/aktuell">aktuell</Link>
    <MenuAccordion title="projekte">
      <Link to="/projekte/s16">S16</Link>
      <Link to="/projekte/h36">H36</Link>
      <Link to="/projekte/h40">H40</Link>
      <Link to="/projekte/medienprojekt-p2">medienprojekt p2</Link>
      <Link to="/projekte/buga-pavillon">buga-pavillon</Link>
      <Link to="/projekte/gartenschau-eppingen">gartenschau eppingen</Link>
      <Link to="/projekte/eberstein">eberstein</Link>
      <Link to="/projekte/kirchensall">kirchensall</Link>
      <Link to="/projekte/raumblick">raumblick</Link>
      <Link to="/projekte/l28">l28</Link>
      <Link to="/projekte/shure">shure</Link>
      <Link to="/projekte/shure-ausbau">shure - ausbau</Link>
      <Link to="/projekte/k12">k12</Link>
      <Link to="/projekte/u-in-d">u in d</Link>
      <Link to="/projekte/h218">h218</Link>
      <Link to="/projekte/arno">arno</Link>
      <Link to="/projekte/xcel-campus">xcel campus</Link>
    </MenuAccordion>
    <MenuAccordion title="büro">
      <Link to="/team">team</Link>
      <Link to="/partner">partner</Link>
      <Link to="/impressum">impressum</Link>
      <Link to="/datenschutz">datenschutz</Link>
    </MenuAccordion>
    <Link to="/stellenausschreibung">stellenausschreibung</Link>
    <Link to="/kontakt">kontakt</Link>
  </>
);
export const MobileMenu = () => (
  <StyledMobile>
    <MenuAccordion title="Menu">
      <Links />
    </MenuAccordion>
  </StyledMobile>
);
export const Menu = () => (
  <StyledMenu>
    <Links />
  </StyledMenu>
);

export const MenuAccordion = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <Accordion.Root type="single" collapsible>
    <AccordionItem value="item-1">
      <AccordionTrigger>{title}</AccordionTrigger>
      <AccordionContent>{children}</AccordionContent>
    </AccordionItem>
  </Accordion.Root>
);

const AccordionItem = styled(Accordion.Item, {
  overflow: "hidden",
  marginTop: 1,
});

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  { children: React.ReactNode }
>(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
    </StyledTrigger>
  </StyledHeader>
));

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <StyledContentText>{children}</StyledContentText>
  </StyledContent>
));

const StyledHeader = styled(Accordion.Header, {
  all: "unset",
  display: "flex",
});

const StyledTrigger = styled(Accordion.Trigger, {
  all: "unset",
  cursor: "pointer",
  fontFamily: "inherit",
  backgroundColor: "transparent",
  fontSize: "inherit",
  color: "rgba(0,0,0,0.8)",
  "&:hover": {
    color: "rgba(0,0,0,0.4)",
  },
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const StyledContent = styled(Accordion.Content, {
  overflow: "hidden",
  color: "rgba(0,0,0,0.8)",
  "&:hover": {
    color: "rgba(0,0,0,0.4)",
  },
  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const StyledContentText = styled("div", {
  marginLeft: "10px",
  marginBottom: "10px",
});
