export const MedienProjektP2 = () => (
  <>
    <img src="/assets/images/other/P2_DSC5207.jpg" alt="" />

    <p>medienprojekt p2</p>
    <p>neubau eines wohn- und bürogebäudes in stuttgart</p>
    <p>LP 1 - 8</p>
    <p>fertigstellung februar 2017</p>
    <p>&nbsp;</p>
    <p>Fotos: Hans Kollmer, Mario Rodrigues</p>

    <img src="/assets/images/other/P2_I_0045.jpg" alt="" />

    <img src="/assets/images/other/P2_I_0034.jpg" alt="" />

    <img src="/assets/images/other/P2_I_0167.jpg" alt="" />

    <img src="/assets/images/other/P2_I_0117.jpg" alt="" />

    <img src="/assets/images/other/P2_I_0221.jpg" alt="" />
  </>
);
