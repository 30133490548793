export const S16 = () => (
  <>
    <img src="/assets/images/other/Aussen01.jpg" alt="" />

    <img src="/assets/images/other/Bad01.jpg" alt="" />

    <img src="/assets/images/other/WC01.jpg" alt="" />

    <img src="/assets/images/other/Strasse02.jpg" alt="" />
  </>
);
