export const H218 = () => (
  <>
    <img src="/assets/images/other/H218_Ansicht_3000_960_661.jpg" alt="" />

    <p>
      H218
      <br />
      sanierung eines denkmalgeschützten mehrfamilienwohnhauses in stuttgart
    </p>
    <p>
      LP 5-8
      <br />
      für Markus Mucha
    </p>
  </>
);
