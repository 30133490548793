import { postClass } from "../stitches.config";

export const Partners = () => {
  return (
    <>
      <div className={postClass()}>
        <img src="/assets/images/other/Mucha_sw_960_288.jpg" alt="" />

        <p>architekturbüro mucha</p>
      </div>

      <div className={postClass()}>
        <img src="/assets/images/other/C-plus_sw.jpg" alt="" />

        <p>c+ interiordesign</p>
      </div>

      <div className={postClass()}>
        <img src="/assets/images/other/AG37_01_sw_960_288.jpg" alt="" />

        <p>ag37</p>
      </div>

      <div className={postClass()}>
        <img
          src="/assets/images/other/Planungsbuero_Engelhardt_sw_960_288.jpg"
          alt=""
        />

        <p>planungsbüro engelhardt</p>
      </div>

      <div>
        <img src="partner_files/Fischer_Partner_sw_960_288.jpg" alt="" />

        <p>fischer und partner</p>
      </div>
    </>
  );
};
