export const Raumblick = () => (
  <>
    <img src="/assets/images/other/_MG_6226.JPG" alt="" />

    <p>
      <strong>raumblick</strong>
      <br />
      büro, lager und ausstellungsgebäude in murr
    </p>
    <p>baubeginn: 2012</p>

    <img src="/assets/images/other/_MG_5252.JPG" alt="" />

    <img src="/assets/images/other/_MG_5273.JPG" alt="" />

    <img src="/assets/images/other/Raumblick_OG_1.jpg" alt="" />
  </>
);
