export const K12 = () => (
  <>
    <img src="/assets/images/other/IMG_1081_960_640.JPG" alt="" />

    <p>
      k12
      <br />
      neubau eines einfamilienwohnhauses in stuttgart
    </p>
    <p>
      LP 1-9
      <br />
      für Markus Mucha
    </p>

    <img src="/assets/images/other/IMG_1068_960_1291.JPG" alt="" />

    <img src="/assets/images/other/IMG_3812_960_640.JPG" alt="" />

    <img src="/assets/images/other/IMG_3860060910_960_640.JPG" alt="" />

    <img src="/assets/images/other/IMG_3763_960_640.JPG" alt="" />
  </>
);
