import { useEffect, useState } from "react";
import { styled } from "../stitches.config";

const StyledWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  minHeight: "50px",
  backgroundColor: "white",
  border: "1px solid black",
  padding: "10px",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  justifyContent: "center",
  "& > button": {
    flexBasis: "20%",
    marginLeft: "15px",
    border: "1px solid black",
    padding: "3px",
  },
  variants: {
    hide: {
      true: {
        display: "none",
      },
    },
  },
});

const StyledContent = styled("div", {
  flexBasis: "80%",
  display: "flex",
  justifyContent: "center",
});

export const CookieBox = () => {
  const [hide, setHide] = useState<boolean>(false);
  useEffect(() => {
    if (document.cookie.indexOf("hide_cookie_banner") !== -1) setHide(true);
  }, [setHide]);
  const hideBox = () => {
    document.cookie =
      "hide_cookie_banner=true; expires=Wed, 18 Dec 2026 12:00:00 GMT";
    setHide(true);
  };
  return (
    <StyledWrapper hide={hide}>
      <StyledContent>
        Für die Bereitstellung unserer Angebote werden auf dieser Seite
        technisch notwendige Cookies gesetzt. Nähere Informationen dazu und zu
        Ihren Rechten als Benutzer finden Sie in unserer Datenschutzerklärung.
        Klicken Sie auf „Ich stimme zu“, um Cookies zu akzeptieren.
      </StyledContent>
      <button onClick={() => hideBox()}>Ich stimme zu</button>
    </StyledWrapper>
  );
};
