import { postClass } from "../stitches.config";

export const Blog = () => {
  return (
    <>
      <div className={postClass()}>
        <img src="/assets/images/other/GARTEN_EPP_Container.jpg" alt="" />

        <p>gartenschau eppingen</p>
        <p>
          nachdem nun die container aufgestellt sind wurde die gartenschau
          leider auf 2022 verschoben.
        </p>
        <p>
          wir freuen uns auf das nächstes jahr mit hoffentlich besseren
          voraussetungen.
        </p>
      </div>

      <div className={postClass()}>
        <img src="/assets/images/other/RAUMBLICK_Baubeginn.jpg" alt="" />

        <p>RAUMBLICK2</p>
        <p>
          nach coronabedingten verzögerungen starten wir nun mit dem zweiten
          bauabschnitt durch
        </p>
      </div>

      <div className={postClass()}>
        <img src="/assets/images/other/WERKSTATT_Holzbau.jpg" alt="" />

        <p>WERKSTATT</p>
        <p>
          der holzbau steht. mit seinen 17,5m freitragenden leimbindern sind dem
          innenausbau keine grenzen gesetzt
        </p>
      </div>

      <div className={postClass()}>
        <p>werkstatt</p>
        <p>für die ausstockung müssen die träger raus</p>
      </div>

      <div className={postClass()}>
        <img src="/assets/images/other/log_960_442.jpg" alt="" />

        <p>
          auch sint/flut ist jetzt endlich bei{" "}
          <a href="http://www.facebook.com/pages/sintflut/491598684197339?ref=hl">
            facebook
          </a>
          !
        </p>
      </div>

      <div className={postClass()}>
        <img src="/assets/images/other/buero.jpg" alt="" />

        <p>
          bezug der neuen bürofläche in der lindenspürstrasse 20, stuttgart-west
        </p>
      </div>

      <div className={postClass()}>
        <img src="/assets/images/other/Bau_Grammy_960_667.jpg" alt="" />

        <p>
          Danke für die tolle Einweihungsfeier nach einem grossartigen Projekt
        </p>
      </div>
    </>
  );
};
