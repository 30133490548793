import { styled, css } from "../stitches.config";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, EffectFade, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/a11y";
import "swiper/css/effect-fade";
import { useRef } from "react";

const slides = [
  "P2_I_0167.jpg",
  "KIRCHENSALL_04_a.jpg",
  "P2_I_0045.jpg",
  "Bad01.jpg",
  "KIRCHENSALL_Final02.jpg",
  "_MG_5252.JPG",
  "P2_DSC5207.jpg",
  "2022-05-18_10.37.14.jpg",
  "2022-05-18_10.37.14_960_540.jpg",
];

/* const swiperClassName = css({ display: "flex", width: "100%", height: "100%" }); */
/* const swiperSlideClassName = css({
  height: "auto",
  width: "auto",
  flexShrink: 0,
  display: "block",
  maxHeight: "100%",
}); */
const imgClass = css({
  display: "block",
  width: "100%",
  height: "auto",
  objectFit: "cover", //"scale-down",
});

const StyledArrow = styled("button", {
  backgroundColor: "transparent",
  display: "none",
  border: "0",
  position: "absolute",
  background: "rgba(0, 0, 0, 0.5)",
  backgroundImage: "url(/assets/images/controls-sprite.png)",
  width: "30px",
  height: "30px",
  marginTop: "-15px",
  borderRadius: "4px",
  filter: "alpha(opacity=80)",
  opacity: "0.8",
  zIndex: "3",
  top: "50%",
  "&:active": {
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  "&:hover": {
    filter: "alpha(opacity=100)",
    opacity: "1",
  },
  "&:disabled": {
    backgroundColor: "rgba(0,0,0,0.5) !important",
    filter: "alpha(opacity=20) !important",
    opacity: "0.2",
  },
  variants: {
    left: {
      true: {
        backgroundPosition: "top left",
        left: "5px",
      },
    },
    right: {
      true: {
        backgroundPosition: "top right",
        right: "5px",
        left: "auto",
      },
    },
  },
  defaultVariants: {
    left: "true",
  },
});
const MainWrapper = styled("div", {
  width: "100%",
  paddingBottom: "56.25%",
  marginBottom: "20px",
  overflow: "hidden",
  maxHeight: "500px",
  position: "relative",
});

const SliderWrapper = styled("div", {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  "&:hover": {
    [`${StyledArrow}`]: {
      display: "block",
    },
  },
});

export const Slider = () => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  return (
    <MainWrapper>
      <SliderWrapper>
        <StyledArrow ref={prevRef} aria-label="prev" />
        <StyledArrow right ref={nextRef} aria-label="next" />
        <Swiper
          modules={[EffectFade, Navigation, A11y, Autoplay]}
          slidesPerView={1}
          slidesPerGroup={1}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          onBeforeInit={(swiper) => {
            if (
              swiper.params.navigation &&
              typeof swiper.params.navigation !== "boolean"
            ) {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }
          }}
          effect="fade"
          spaceBetween={30}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide}>
              <img
                src={"/assets/images/mainSlider/" + slide}
                alt={slide}
                className={imgClass()}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </SliderWrapper>
    </MainWrapper>
  );
};
