export const Jobs = () => {
  return (
    <>
      <img src="/assets/images/other/drei_bilder.jpg" alt="" />

      <p>
        sint/flut architektur ist ein im Raum Heilbronn tätiges Architekturbüro
        mit Sitz in Flein. Wir bearbeiten alle Leistungsphasen, von gehobenen
        Einfamilienwohnhäusern zu Industriebauten, von Detailverliebtheit bis
        Großbaustelle.
      </p>
      <p>
        Für die Verstärkung unseres Teams suchen wir ab sofort
        <br />
        <br />
        -eine(n) Architekt(in), Ingenieur(in),
        <br />
        -technische(n) Mitarbeiter(in), Bauzeichner(in)
      </p>
      <p>
        <br />
        Wir erwarten:
        <br />
        - engagierte Kollegen idealerweise mit einschlägiger Berufserfahrung
        in&nbsp;&nbsp;&nbsp; allen Leistungsphasen
        <br />
        - sicheren Umgang mit CAD Software
        <br />- Spaß an kreativer und abwechslungsreicher Arbeit
      </p>
      <p>
        Wir bieten:
        <br />
        - abwechslungsreiche, interessante Projekte
        <br />
        - eine unbefristete Anstellung in Vollzeit/Teilzeit
        <br />
        - Raum für persönliche Entwicklung
        <br />
        - flexible Arbeitszeiten und –räume
        <br />- eine kollegiale Arbeitsathmosphäre
      </p>
      <p>Wir freuen uns über Ihre aussagekräftige Bewerbung.</p>

      <img src="/assets/images/other/AIP.jpg" alt="" />

      <img src="/assets/images/other/Werkstudent.jpg" alt="" />

      <img src="/assets/images/other/Praktikum.jpg" alt="" />
    </>
  );
};
